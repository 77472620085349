import React from "react";
import { CircleSpinner } from "react-spinners-kit";

import { useCheckWhitelist } from "../hooks";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

const Whitelist: React.FC = (_props) => {
  const [addressInput, setAddressInput] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const { data: whitelisted, isLoading, error } = useCheckWhitelist(address);

  function handleAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAddressInput(event.target.value);
  }

  function handleCheck(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    setAddress(addressInput);
  }

  const WhitelistedMessage = React.useMemo(() => {
    if (typeof whitelisted === "undefined") {
      return null;
    }

    return whitelisted ? (
      <SuccessMessage message="Wallet address is whitelisted." />
    ) : (
      <ErrorMessage message="Wallet address is NOT whitelisted." />
    );
  }, [whitelisted]);

  return (
    <>
      <ErrorMessage message={error?.message} />
      {WhitelistedMessage}

      <div className="w-full max-w-xl pb-10">
        <input
          type="search"
          className="shadow-sm block w-full border-gray-300 rounded-3xl py-6 px-4 sm:px-6 text-2xl"
          placeholder="Wallet Address"
          onChange={handleAddressChange}
        />
      </div>

      <button
        className="uppercase font-bold button-height flex bg-light-yellow text-2xl rounded-3xl py-6 px-12 hover:opacity-90 mx-auto xl:mx-0 lg:mx-0"
        onClick={handleCheck}
        disabled={isLoading}
      >
        {isLoading ? <CircleSpinner color="black" /> : "Check"}
      </button>
    </>
  );
};

export default Whitelist;
