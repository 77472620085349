import { useQuery } from "react-query";

import whitelist_import from "./utils/whitelist.json";
import { ethers } from "ethers";

let whitelist: any = {};

whitelist_import.map((add: any) => {
  if (ethers.utils.isAddress(add)) {
    whitelist[ethers.utils.getAddress(add)] = true;
  }
});

export function useCheckWhitelist(walletAddress: string) {
  return useQuery<any, Error>(
    ["whitelist", walletAddress],
    () => {
      if (!ethers.utils.isAddress(walletAddress.toLowerCase())) {
        throw new Error("This is not a valid address");
      }

      let wallet = ethers.utils.getAddress(walletAddress.toLowerCase());
      return whitelist[wallet] ? true : false;
    },
    {
      enabled: Boolean(walletAddress),
      retry: false,
      staleTime: 60 * 60 * 1000,
    }
  );
}
