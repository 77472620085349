/* eslint-disable jsx-a11y/alt-text */
import { QueryClient, QueryClientProvider } from "react-query";

import Whitelist from "./components/Whitelist";
import "./styles.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div
        className="font-sans w-full sm:h-full"
        style={{ backgroundColor: "#2B1B43" }}
      >
        <div className="w-full max-w-8xl min-h-screen p-3 md:p-10 lg:p-17">
          <div className="relative flex w-full h-auto flex-col pt-16 md:pt-28">
            <div className="h-auto w-full flex flex-col justify-start items-center">
              <img
                alt="logo"
                className="max-w-xl 2xl:max-w-lg w-full pb-10 sm:px-4"
                src="img/logo512.png"
              />
              <Whitelist />
            </div>
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
}
